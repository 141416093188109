<template>
  <div>
    <div
      class="flex flex-col gap-6 w-full max-w-7xl mx-auto justify-center px-4 py-8 md:p-4"
    >
      <h1 class="font-bold text-5xl text text-white max-w-xl !leading-tight">
        {{ $t("landing.search.title") }}
      </h1>
      <h2 class="font-semibold text-2xl text text-white">
        {{ $t("landing.search.subtitle") }}
      </h2>
      <div class="flex w-full max-w-2xl flex-wrap gap-2 md:gap-0">
        <input
          v-model="currentTextInput"
          type="search"
          class="p-4 flex-grow h-16 focus-visible:outline-primary rounded-lg md:!rounded-br-none md:!rounded-tr-none"
          :placeholder="$t('landing.search.placeholder')"
          @keypress.enter="goToSearch"
        />
        <LocationIndicator
          :input-city="cityFilter"
          class="flex-shrink-0 h-16 bg-gray-200 rounded-lg md:!rounded-none w-full md:!w-48 overflow-hidden z-10"
        />
        <button
          class="bg-primary h-16 flex flex-shrink-0 gap-2 md:!w-16 w-full justify-center items-center rounded-lg md:!rounded-bl-none md:!rounded-tl-none"
          @click="goToSearch"
        >
          <IconComponent
            pack="fas"
            class="text-white"
            size="is-large"
            icon="search"
          />
          <p class="md:!hidden flex text-white font-semibold">
            {{ $t("landing.search.cta") }}
          </p>
        </button>
      </div>
      <p class="text-base text text-white">
        {{ $t("landing.search.instructions") }}
      </p>
    </div>
  </div>
</template>

<script>
import LocationIndicator from "@/components/_refactored/search/LocationIndicator.vue";

export default {
  components: {
    LocationIndicator,
  },
  data() {
    return {
      currentTextInput: "",
    };
  },
  computed: {
    cityFilter: {
      get() {
        return this.$store.getters["search/city"];
      },
      set(value) {
        this.$store.dispatch("search/setCity", value);
      },
    },
  },
  methods: {
    goToSearch(event) {
      try {
        event.target.blur();
      } catch (error) {
        console.info("Cant defocus input field");
      }

      this.$trackers.trackEvent({
        title: "Clicked on Full Text Search",
        data: {
          citySeoName: this.cityFilter.seoName,
          search: this.currentTextInput,
          source: "landing",
        },
      });
      this.$router.push(
        this.localePath({
          name: "search",
          query: { search: this.currentTextInput },
        })
      );
    },
  },
};
</script>
