<template>
  <div>
    <div
      class="flex items-center h-full hover:bg-primary/20 cursor-pointer"
      :class="compact ? 'px-3 py-2 gap-2' : 'px-4 py-2 gap-4'"
      @click="showModal = true"
    >
      <IconComponent
        v-if="!cityFilterLoaded"
        class="text-primary animate-spin"
        icon="spinner"
      />

      <IconComponent v-else class="text-primary" icon="map-marker-alt" />
      <div v-if="!iconOnly || !compact" class="flex flex-col">
        <p v-if="!compact" class="font-semibold text-xs">
          {{ $t("locationIndicator.title") }}
        </p>
        <p
          v-if="!iconOnly"
          class="text-primary font-bold"
          :class="compact ? 'text-sm' : 'text-base'"
        >
          {{ selectedCity.name }}
        </p>
      </div>
    </div>

    <!-- City selector modal -->
    <CitySelectorModal
      v-if="showModal"
      :current-city="selectedCity"
      class="z-100"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import CitySelectorModal from "~/components/_refactored/search/CitySelectorModal.vue";
export default {
  components: {
    CitySelectorModal,
  },
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    cityFilterLoaded() {
      return this.$store.getters["search/loaded"];
    },
    selectedCity() {
      return this.$store.getters["search/city"];
    },
    loading() {
      return this.$store.getters["search/loading"];
    },
  },
};
</script>
