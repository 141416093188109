<template>
  <div class="fixed top-0 left-0 w-full h-full flex justify-center isolate p-4">
    <div
      class="fixed top-0 left-0 w-full h-full bg-black/80"
      @click="$emit('close')"
    />
    <div
      class="flex flex-col bg-white rounded-lg p-4 z-10 gap-4 w-full h-max max-w-2xl relative mt-12 md:my-auto"
    >
      <div
        class="absolute top-4 right-4 w-8 h-8 flex items-center justify-center bg-typo-secondary/10 rounded-full cursor-pointer hover:bg-typo-secondary/20"
        @click="$emit('close')"
      >
        <IconComponent class="text-lg text-typo-primary" icon="times" />
      </div>

      <div class="flex flex-col gap-2">
        <p class="text-2xl font-bold text-primary">
          {{ $t("locationIndicator.modal.title") }}
        </p>
        <p class="text-typo-primary">
          {{ $t("locationIndicator.modal.description") }}
        </p>
      </div>

      <AutocompleteCity
        ref="city"
        class="w-full"
        autofocus
        :placeholder="currentCity.name"
        @select="selectCity"
      />

      <button
        class="px-8 py-4 font-semibold text-white bg-primary rounded-md"
        @click="setCity"
      >
        {{ $t("locationIndicator.modal.cta") }}
      </button>
    </div>
  </div>
</template>

<script>
import AutocompleteCity from "~/components/AutocompleteCity.vue";

export default {
  components: {
    AutocompleteCity,
  },
  props: {
    currentCity: {
      type: Object,
      default: () => ({
        name: "",
        seoName: "",
      }),
    },
  },
  data() {
    return {
      selectedCity: null,
    };
  },
  methods: {
    selectCity(city) {
      this.selectedCity = city;
    },
    setCity() {
      if (this.selectedCity) {
        this.$store.dispatch("search/setCity", this.selectedCity);
        this.$trackers.trackEvent({
          title: "Selected City In AutoCity",
          data: {
            citySeoName: this.selectedCity.seoName,
          },
        });
        this.$joszaki.searchCitySet(this.selectedCity);
      }
      this.$emit("close");
    },
  },
};
</script>

<style></style>
