<template>
  <div class="px-4 py-8 w-96">
    <h1 class="text-white text-4xl font-bold mb-5">
      Nincs időd<br />
      böngészni?
    </h1>
    <div class="text-white font-semibold mb-5">
      <div>Ingyenes ajánlatkérés</div>
      <div>Értékelt szakemberek</div>
      <div>2 perc alatt</div>
    </div>
    <JoszakiTextAreaOld
      v-model="offerText"
      class="mb-4 w-full"
      placeholder="Írd le a feledatot"
    />
    <JoszakiButton class="mb-2 w-full h-14 font-bold" @click="requestTender">
      Ajánlatot kérek
    </JoszakiButton>
    <span class="text-white">
      Írd le a feladatot és válogass a jelentkező szakemberek közül
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      offerText: "",
    };
  },
  methods: {
    requestTender() {
      this.$emit("requestTender", this.offerText);
    },
  },
};
</script>

<style></style>
