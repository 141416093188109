var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-[#233445] py-14 is-hidden-mobile"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-multiline",staticStyle:{"margin":"0"}},[_c('div',{staticClass:"column is-full justify-center align-middle flex-1 flex flex-col mb-4"},[_c('div',{staticClass:"flex align-middle justify-center pb-3 text-5xl"},[_c('IconComponent',{staticClass:"text-white",attrs:{"icon":"wrench"}})],1),_c('div',{staticClass:"flex align-middle justify-center text-white text-2xl"},[_vm._v(" "+_vm._s(_vm.$t("personList.professions"))+" ")])]),_c('div',{staticClass:"column is-half"},[_c('section',[_c('h1',{staticClass:"text-2xl text-[#c7e6ff] pb-8"},[_vm._v(" "+_vm._s(_vm.$t("professionLinks.professionGroups"))+" ")])]),_c('div',{staticClass:"columns pl-5"},_vm._l((_vm.groups),function(array,index){return _c('div',{key:index,staticClass:"column is-half"},_vm._l((array),function(professionGroup){return _c('div',{key:`plpg${professionGroup.id}`,staticClass:"mb-2"},[_c('NuxtLink',{staticClass:"text-white hover:text-white mr-auto",attrs:{"to":_vm.localePath({
                    name: 'professionsPage',
                    params: { professionGroup: professionGroup.seoName },
                  })}},[_vm._v(" "+_vm._s(professionGroup.name)+" ")])],1)}),0)}),0)]),_c('div',{staticClass:"column is-half"},[_c('section',[_c('h1',{staticClass:"text-2xl text-[#c7e6ff] pb-8"},[_vm._v(" "+_vm._s(_vm.$t("professionLinks.popularProfessions"))+" ")])]),_c('div',{staticClass:"columns pl-5"},_vm._l((_vm.professionsArrays),function(professionArray,index){return _c('div',{key:index,staticClass:"column is-half"},_vm._l((professionArray),function(profession){return _c('div',{key:`plp${profession.id}`,staticClass:"mb-2"},[_c('NuxtLink',{staticClass:"text-white hover:text-white mr-auto profession-link",attrs:{"to":_vm.localePath({
                    name: 'professionPage',
                    params: { profession: profession.seoName },
                  })}},[_vm._v(" "+_vm._s(profession.name)+" ")])],1)}),0)}),0)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }