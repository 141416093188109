<template>
  <div>
    <div class="w-full bg-primary-darkest">
      <div
        v-if="$ab.isVariant('landing-search', 'test')"
        class="w-full relative bg-grey-200 lg:h-[50rem] flex justify-center"
      >
        <img
          class="absolute w-full h-full top-0 left-0 opacity-20 object-cover"
          v-lazy-load data-src="@/assets/img/landing/landing_image_01.webp"
          alt="Joszaki landing background"
        />
        <div
          class="grid grid-cols-1 lg:grid-cols-[2fr_1fr] 2xl:gap-10 w-full h-full relative"
        >
          <div class="flex items-center justify-start lg:justify-center">
            <SearchLanding />
            <!-- class="flex flex-col justify-center lg:items-start w-full max-w-7xl" -->
          </div>
          <div
            class="flex items-center justify-start lg:justify-start 2xl:justify-start w-full h-full bg-primary-darkest lg:pl-6"
          >
            <LandingOffer
              class="flex flex-col justify-center lg:items-start"
              @requestTender="
                onRequestTender({ source: 'landingOffer', description: $event })
              "
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="testimonials text-center px-3 py-5">
          <p
            class="text-white font-extrabold mb-10 mt-4 text-3xl lg:text-4xl xl:text-5xl"
          >
            {{ $t("landing.header") }}
          </p>
          <div>
            <TestimonialSlider />
          </div>
        </div>
      </div>

      <div
        class="mx-auto max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl px-3 py-14"
      >
        <OfferOrSearch
          @requestTender="
            onRequestTender({ source: 'quickOffer', description: $event })
          "
        />
        <div class="grid grid-cols-2 py-14 pb-28">
          <div
            class="flex flex-col items-center justify-center text-center text-white"
          >
            <IconComponent
              pack="fas"
              class="text-white !text-4xl mb-10"
              size="is-large"
              icon="user"
            />
            <p class="font-extrabold text-2xl px-5 text-center">
              <i18n path="landing.joinedSinceStart">
                <template #professionalCount>
                  <span class="text-orange-500">{{ professionalCount }}</span>
                </template>
              </i18n>
            </p>
          </div>
          <div
            class="flex flex-col items-center justify-center text-center text-white px-10"
          >
            <IconComponent
              pack="fas"
              class="text-white !text-4xl mb-10"
              size="is-large"
              icon="question-circle"
            />
            <p class="font-extrabold text-2xl px-5">
              {{ $t("landing.areYouPro") }}
            </p>
            <p class="font-extrabold text-2xl px-5">
              <NuxtLink
                class="text-primary hover:text-primary-light"
                :to="localePath({ name: 'registrationPage' })"
              >
                {{ $t("landing.register") }}
              </NuxtLink>
              {{ $t("landing.forFree") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <ProfessionLinks />
  </div>
</template>

<script>
import TestimonialSlider from "~/components/mainpage/TestimonialSlider.vue";
import ProfessionLinks from "~/components/people/ProfessionLinks.vue";
import OfferOrSearch from "~/components/mainpage/OfferOrSearch.vue";
import { useRequestTender } from "~/composables/requestTender";

import SearchLanding from "@/components/_refactored/mainpage/SearchLanding.vue";
import LandingOffer from "~/components/_refactored/mainpage/LandingOffer.vue";

import JoszakiLogo from "~/assets/joszaki-logo.svg";

export default {
  name: "IndexPage",
  components: {
    TestimonialSlider,
    ProfessionLinks,
    OfferOrSearch,
    SearchLanding,
    LandingOffer,
  },
  layout: "DefaultLayout",
  setup() {
    return {
      ...useRequestTender(),
    };
  },
  asyncData(context) {
    try {
      context.store.dispatch("layout/setShowSlogan", true);
    } catch (err) {
      console.error("failed to load main page", err);
      context.$sentry.captureException(JSON.stringify(err), {
        tags: {
          main_page: "error",
        },
      });
    }
  },
  data() {
    return {
      professionalCount: 71665,
    };
  },
  head() {
    return {
      title: this.$t("seo.mainPage.metaTitle"),
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "http://schema.org",
            "@type": "Corporation",
            "@id": this.$helpers.topDomain,
            name: this.$t("branding.siteName"),
            logo: JoszakiLogo,
            telephone: this.$helpers.support.phone,
            email: this.$helpers.support.email,
            sameAs: Object.values(this.$helpers.socialNetworkUrls).filter(
              (value) => !!value
            ),
            url: this.$helpers.topDomain,
            image: JoszakiLogo,
            description: this.$t("seo.mainPage.schemaDescription"),
            address: {
              "@type": "PostalAddress",
              streetAddress: "Andrássy út 66",
              addressLocality: "Budapest",
              addressRegion: "Pest",
              postalCode: "1062",
            },
          },
        },
      ],
      meta: [
        {
          hid: this.$t("branding.siteName"),
          name: this.$t("branding.webpage"),
          content: this.$t("seo.mainPage.metaDescription"),
        },
      ],
      link: [
        {
          rel: "canonical",
          href: this.$helpers.topDomain,
        },
      ],
    };
  },
  mounted() {
    this.$trackers.pageview({
      pagename: this.$trackers.pageNames.HOME_PAGE,
    });
  },
  beforeDestroy() {
    this.$trackers.pageleave({
      pagename: this.$trackers.pageNames.HOME_PAGE,
    });
  },
  methods: {
    onRequestTender({ person, source, description } = {}) {
      this.requestTender({
        source,
        person,
        profession: this.profession,
        city: this.city,
        description,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@use "styles/_all-members" as global;

.person-swiper {
  // Swiper holder overrides
  margin-bottom: 10px;

  ::v-deep .swiper-main {
    padding-top: 0px;
  }
}

.loadingLook {
  opacity: 0.1;
  pointer-events: none;
}

.peopleListLoader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
}

.browseCities {
  margin-top: 40px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
}

.headingBlock {
  margin-top: 40px;
  margin-bottom: 10px;
  padding-left: 10px;

  h1 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: global.$primary;
  }

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: global.$blogTextColor;
  }
}

.browseBlockBase {
  $bodyBg: rgba(64, 79, 91, 0.05);
  margin-bottom: 40px;

  .contentHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include global.touch {
      flex-direction: column;
    }

    .ptTabs {
      display: flex;
      align-items: center;
      user-select: none;
      max-width: calc(100% - 300px);
      overflow-x: auto;
      @include global.touch {
        max-width: unset;
        width: 100%;
      }

      .ptTab {
        padding: 15px 20px;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        white-space: nowrap;
        border-radius: 12px 12px 0px 0px;

        &::first-letter {
          text-transform: capitalize;
        }

        &.active {
          color: global.$primary;
          font-weight: 700;
          background: $bodyBg;
        }
      }
    }

    .cityButtons {
      display: flex;
      align-items: center;
      user-select: none;
      background: $bodyBg;
      border-radius: 12px 12px 0px 0px;
      padding: 15px 15px;
      padding-bottom: 10px;
      max-width: calc(100% - 300px);
      overflow-x: auto;
      @include global.touch {
        max-width: unset;
        width: 100%;
      }

      .cityButton {
        padding: 15px 20px;
        padding-bottom: 10px;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        white-space: nowrap;
        border-radius: 12px 12px 0px 0px;
        font-size: 14px;
        padding: 5px 15px;
        background: white;
        border-radius: 3px;
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0px;
        }

        &::first-letter {
          text-transform: capitalize;
        }

        &.active {
          font-weight: 700;
          background: global.$primary;
          color: white;
        }
      }
    }

    .search {
      padding: 15px;
      padding-bottom: 0px;
      background: $bodyBg;
      border-radius: 12px 12px 0px 0px;
      @include global.touch {
        width: 100%;
        border-radius: 0px;
      }
    }
  }

  .contentBody {
    border-radius: 0px 0px 13px 13px;
    width: 100%;
    padding: 15px;
    position: relative;
    background: $bodyBg;

    p {
      font-weight: 600;
      font-size: 18px;
      line-height: 160%;
      color: global.$blogTextColor;
      margin-bottom: 1rem;
    }

    button {
      margin-bottom: 1rem;
    }

    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      color: global.$primary;
      margin-bottom: 5px;
    }
  }
}

.testimonials {
  background: url("~@/assets/img/lading-hero-c.jpg") center center;
  background-size: cover;
}

.adviceBlock {
  margin-top: 40px;
  margin-bottom: 24px;
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;

  .advices {
    grid-column: span 2;
    max-width: 800px;
    @include global.touch {
      grid-column: span 3;
      max-width: unset;
    }

    .advice {
      .adviceBlockTitle {
        font-family: "Nunito";
        font-weight: 700;
        font-size: 32px;
        color: global.$blogTextColorLight;
      }
    }
  }

  .sideBlocks {
    order: 0;
    position: relative;
    @include global.touch {
      display: none;
    }

    .floater {
      position: relative;
      position: sticky;
      top: 1rem;
      display: grid;
      gap: 1rem;
    }
  }
}

.noAdviceContentBlock {
  width: 100%;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  margin-bottom: 20px;
}
</style>
